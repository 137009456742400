import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
        {/* Page Header Start */}
        <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
          About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
              About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5">
                  Freight Forwarding with Precision and Care.
                </h1>

                <p className="mb-4">
                  Utilizing our well-developed expertise and an extensive
                  network of industry affiliations, we possess a substantial
                  array of resources that enable us to provide our clients with
                  the most competitive pricing options available within the
                  current market landscape. Our dedication surpasses
                  conventional approaches; we persistently explore innovative
                  strategies to reduce costs and fortify revenues for our
                  esteemed clientele.
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="d-flex align-items-center">
                        <div className="btn-lg-square bg-primary rounded-circle me-3">
                          <i className="fa fa-phone-alt text-white" />
                        </div>
                        <h5 className="mb-0">{companynumber}</h5>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex align-items-center">
                        <div className="btn-lg-square bg-primary rounded-circle me-3">
                          <i className="fa fa-envelope text-white" />
                        </div>
                        <h5 className="mb-0">{companyemail}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-3">
                <img
                  className="img-fluid  wow zoomIn"
                  data-wow-delay="0.7s"
                  src="img/about1.jpg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Features Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
           
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div
                className="position-relative rounded overflow-hidden h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/About2.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="display-6 mb-5">Why Choose Us!</h1>
              <p className="mb-5">
                At the heart of our array of services lies a comprehensive suite
                of solutions that encompasses both Freight Forwarding and
                Trading. With our profound comprehension of the intricacies
                within the logistics sector, we adeptly navigate the
                complexities involved in the movement of goods across
                international borders and diverse continents. Simultaneously,
                our trading proficiency opens doors to strategic collaborations,
                sourcing, and distribution, thereby amplifying your potential
                for substantial growth.
              </p>
              <div className="d-flex mb-5">
                <div
                  className="flex-shrink-0 btn-square bg-primary rounded-circle"
                  style={{ width: "90px", height: "90px" }}
                >
                  <i class="fa fa-3x fa-eye" style={{ color: "white" }}></i>
                </div>
                <div className="ms-4">
                  <h5 className="mb-3">Our Vision</h5>
                  <span>
                    Cultivating Robust Customer Relationships and Guaranteeing
                    Sustained Success in Contract Logistics for {companyname}.
                  </span>
                </div>
              </div>
              <div className="d-flex mb-5">
                <div
                  className="flex-shrink-0 btn-square bg-primary rounded-circle"
                  style={{ width: "90px", height: "90px" }}
                >
                  <i
                    class="fa fa-3x fa-bullseye"
                    style={{ color: "white" }}
                  ></i>
                </div>
                <div className="ms-4">
                  <h5 className="mb-3">Our Mission</h5>
                  <span>
                    Transforming Customer Perspectives by Providing More Than
                    Just Services – Providing Peace of Mind through the Reliable
                    and Secure Hands of {companyname}.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End */}
      {/* Service Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <h1 className="display-6 mb-5">What We Do</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i class="fa fa-3x fa-plane" style={{ color: "white" }}></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Air">
                    Air Freight
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img className="img-fluid" src="img/Sea Freight.jpg" alt="" />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i class="fa fa-3x fa-ship" style={{ color: "white" }}></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Sea">
                    Sea Freight
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img className="img-fluid" src="img/Road Freight.jpg" alt="" />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i class="fa fa-3x fa-truck" style={{ color: "white" }}></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Road">
                    Road Freight
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img
                  className="img-fluid"
                  src="img/Customs Clearance.jpg"
                  alt=""
                />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i
                      class="fas fa-hard-hat fa-3x "
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Customs">
                    Customs Clearance
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img className="img-fluid" src="img/Warehousing.jpg" alt="" />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i
                      class="fa fa-3x fa-warehouse"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Warehousing">
                    Warehousing
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img className="img-fluid" src="img/Project Cargo.jpg" alt="" />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i
                      class=" fa-3x fas fa-dolly-flatbed"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Project">
                    Project Cargo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
