import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };

  return (
    <>
 <div className="container-fluid bg-dark text-white-50 py-2 px-0 d-none d-lg-block">
          <div className="row gx-0 align-items-center">
            <div className="col-lg-7 px-5 text-start">
              <div className="h-100 d-inline-flex align-items-center me-4">
                <small className="fa fa-phone-alt me-2" />
                <small>{companynumber}</small>
              </div>
              <div className="h-100 d-inline-flex align-items-center me-4">
                <small className="far fa-envelope-open me-2" />
                <small>{companyemail}</small>
              </div>
            </div>
            <div className="col-lg-5 px-5 text-end">
              <ol className="breadcrumb justify-content-end mb-0">
                <li className="breadcrumb-item"><Link className="text-white-50 small" to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link className="text-white-50 small" to="/Terms">Terms</Link></li>
                <li className="breadcrumb-item"><Link className="text-white-50 small" to="/Privacy">Privacy</Link></li>
                <li className="breadcrumb-item"><Link className="text-white-50 small" to="/Contact">Contact Us</Link></li>
              </ol>
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* Navbar Start */}
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5">
          <Link to="/Home" className="navbar-brand d-flex align-items-center">
          <img src="img/logo.png" style={{}}></img>
          </Link>
          <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"/>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav mx-auto bg-light pe-4 py-3 py-lg-0">
              <Link to="/Home" className="nav-item nav-link ">Home</Link>
              <Link to="/About" className="nav-item nav-link">About Us</Link>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Our Services</Link>
                <div className="dropdown-menu bg-light border-0 m-0">
                  <Link to="/Air" className="dropdown-item">Air Freight</Link>
                  <Link to="/Sea" className="dropdown-item">Sea Freight</Link>
                  <Link to="/Road" className="dropdown-item">Road Freight</Link>
                  <Link to="/Customs" className="dropdown-item">Customs Clearance</Link>
                  <Link to="/Warehousing" className="dropdown-item">Warehousing</Link>
                  <Link to="/Project" className="dropdown-item">Project Cargo</Link>
                </div>
              </div>
              <Link to="/Trading" className="nav-item nav-link ">Trading Solutions</Link>
              <Link to="/Careers" className="nav-item nav-link">Careers</Link>
              <Link to="/Contact" className="nav-item nav-link">Contact Us</Link>
            </div>
            <div className="h-100 d-lg-inline-flex align-items-center ">
            <Link to="/Getquote" className="btn btn-primary py-3 px-5 animated slideInDown">Request A Quote</Link> </div>
          </div>
        </nav>
    </>
  );
};

export default Header;
