import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <div
        className="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-md-6">
              <img
                className="img-fluid me-3"
                src="img/logo.png"
                alt=""
                style={{ height: "120px" }}
              />
              <br />
              <span>
                Utilizing our well-developed expertise and an extensive network
                of industry affiliations, we possess a substantial array of
                resources that enable us to provide our clients with the most
                competitive pricing options available within the current market
                landscape.
              </span>
            </div>
            <div className="col-md-6">
              <h5 className="text-light mb-4">Newsletter</h5>
              <p>
                Get a competitive edge in the freight industry. Subscribe to our
                newsletter today.
              </p>
              <div className="position-relative">
                <form
                  className=""
                  action="/php/thankyou-subscribe.php "
                  method="POST"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <input
                    onChange={(event) => handleSubemailChange(event)}
                    id="subemail"
                    value={subemail}
                    type="email"
                    name="subemail"
                    placeholder="Your email address"
                    required
                    className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                  />
                  <button
                    className="btn btn-primary py-2 px-3 position-absolute top-0 end-0 mt-2 me-2"
                    type="submit"
                    name="submit"
                  >
                    Subscribe Now
                  </button>
                </form>
                <h5>{result}</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <h5 className="text-light mb-4">Get In Touch</h5>
              <p>
                <i className="fa fa-map-marker-alt me-3" />
                {companyaddress}
              </p>
              <p>
                <i className="fa fa-phone-alt me-3" />
                {companynumber}
              </p>
              <p>
                <i className="fa fa-envelope me-3" />
                {companyemail}
              </p>
            </div>
            <div className="col-lg-4 col-md-6">
              <h5 className="text-light mb-4">Our Services</h5>
              <Link className="btn btn-link" to="/Air">
                Air Freight
              </Link>
              <Link className="btn btn-link" to="/Sea">
                Sea Freight
              </Link>
              <Link className="btn btn-link" to="/Road">
                Road Freight
              </Link>
              <Link className="btn btn-link" to="/Customs">
                Customs Clearance
              </Link>
              <Link className="btn btn-link" to="/Warehousing">
                Warehousing
              </Link>
              <Link className="btn btn-link" to="/Project">
                Project Cargo
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <h5 className="text-light mb-4">Quick Links</h5>
              <Link className="btn btn-link" to="/Home">
                Home
              </Link>
              <Link className="btn btn-link" to="/About">
                About Us
              </Link>
             
              <Link className="btn btn-link" to="/Trading">
                Trading Solutions
              </Link>
              <Link className="btn btn-link" to="/Contact">
                Contact Us
              </Link>
              <Link className="btn btn-link" to="/Getquote">
                Request For Quote
              </Link>
            </div>
          </div>
        </div>
        <div className="container-fluid copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                ©2023 <Link to="/">{companyname}</Link>, All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
