import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Warehousing
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Warehousing
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">Warehousing</h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Warehousing.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    Devoted to delivering an unparalleled level of service, we
                    take upon ourselves the intricate task of orchestrating,
                    from inception to fruition, the intricate dance of supply
                    chain dynamics. With an unwavering focus on efficiency, we
                    meticulously handle the meticulous planning, seamless
                    execution, and vigilant control of each operation. Our aim
                    is crystal clear: to ensure that every facet of the supply
                    chain operates harmoniously, optimizing resources and time,
                    and ultimately enhancing your overall business performance.
                  </p>

                  <p>
                    A cornerstone of our comprehensive approach lies in our
                    extensive network of warehouses strategically positioned
                    across key geographic points. These warehouses aren't just
                    physical spaces; they are pivotal hubs of synchronized
                    activity where goods find temporary repose before embarking
                    on their onward journey. Our network is carefully designed
                    and strategically distributed to ensure maximum coverage and
                    accessibility, allowing us to cater to the diverse needs of
                    our clientele. Whether it's storage, consolidation, or
                    distribution, our warehouses stand as pillars of
                    reliability, ready to accommodate and serve.
                  </p>

                  <p>
                    In a landscape where the global market is characterized by
                    swift fluctuations and demands are ever-evolving, our
                    network of warehouses acts as a responsive and agile force.
                    By offering dynamic solutions that adapt to the changing
                    demands of your business, we empower you with the means to
                    flexibly allocate resources, optimize inventory management,
                    and swiftly respond to market shifts. This multi-pronged
                    approach, combining meticulous supply chain oversight with a
                    strategically positioned warehouse network, stands as a
                    testament to our commitment to your success.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
