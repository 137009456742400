import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />{" "}
      <div className="container-fluid p-0 mb-5 w3-banner jarallax">
        <video
          className="img-fluid"
          autoPlay
          muted
          loop
          style={{ zIndex: "-1" }}
        >
          <source src="img/video.mp4" type="video/mp4" />
        </video>
      </div>
      {/* Carousel End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5">
                  Freight Forwarding with Precision and Care.
                </h1>

                <p className="mb-4">
                  Utilizing our well-developed expertise and an extensive
                  network of industry affiliations, we possess a substantial
                  array of resources that enable us to provide our clients with
                  the most competitive pricing options available within the
                  current market landscape. Our dedication surpasses
                  conventional approaches; we persistently explore innovative
                  strategies to reduce costs and fortify revenues for our
                  esteemed clientele.
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="d-flex align-items-center">
                        <div className="btn-lg-square bg-primary rounded-circle me-3">
                          <i className="fa fa-phone-alt text-white" />
                        </div>
                        <h5 className="mb-0">{companynumber}</h5>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex align-items-center">
                        <div className="btn-lg-square bg-primary rounded-circle me-3">
                          <i className="fa fa-envelope text-white" />
                        </div>
                        <h5 className="mb-0">{companyemail}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-3">
                <img
                  className="img-fluid  wow zoomIn"
                  data-wow-delay="0.7s"
                  src="img/about1.jpg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <h1 className="display-6 mb-5">What We Do</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i class="fa fa-3x fa-plane" style={{ color: "white" }}></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Air">
                    Air Freight
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img className="img-fluid" src="img/Sea Freight.jpg" alt="" />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i class="fa fa-3x fa-ship" style={{ color: "white" }}></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Sea">
                    Sea Freight
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img className="img-fluid" src="img/Road Freight.jpg" alt="" />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i class="fa fa-3x fa-truck" style={{ color: "white" }}></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Road">
                    Road Freight
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img
                  className="img-fluid"
                  src="img/Customs Clearance.jpg"
                  alt=""
                />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i
                      class="fas fa-hard-hat fa-3x "
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Customs">
                    Customs Clearance
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img className="img-fluid" src="img/Warehousing.jpg" alt="" />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i
                      class="fa fa-3x fa-warehouse"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Warehousing">
                    Warehousing
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img className="img-fluid" src="img/Project Cargo.jpg" alt="" />
                <div className="d-flex align-items-center bg-light">
                  <div className="service-icon flex-shrink-0 bg-primary">
                    <i
                      class=" fa-3x fas fa-dolly-flatbed"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <Link className="h4 mx-4 mb-0" to="/Project">
                    Project Cargo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div
                className="position-relative rounded overflow-hidden h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/About2.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="display-6 mb-5">Why Choose Us!</h1>
              <p className="mb-5">
                At the heart of our array of services lies a comprehensive suite
                of solutions that encompasses both Freight Forwarding and
                Trading. With our profound comprehension of the intricacies
                within the logistics sector, we adeptly navigate the
                complexities involved in the movement of goods across
                international borders and diverse continents. Simultaneously,
                our trading proficiency opens doors to strategic collaborations,
                sourcing, and distribution, thereby amplifying your potential
                for substantial growth.
              </p>
              <div className="d-flex mb-5">
                <div
                  className="flex-shrink-0 btn-square bg-primary rounded-circle"
                  style={{ width: "90px", height: "90px" }}
                >
                  <i class="fa fa-3x fa-eye" style={{ color: "white" }}></i>
                </div>
                <div className="ms-4">
                  <h5 className="mb-3">Our Vision</h5>
                  <span>
                    Cultivating Robust Customer Relationships and Guaranteeing
                    Sustained Success in Contract Logistics for {companyname}.
                  </span>
                </div>
              </div>
              <div className="d-flex mb-5">
                <div
                  className="flex-shrink-0 btn-square bg-primary rounded-circle"
                  style={{ width: "90px", height: "90px" }}
                >
                  <i
                    class="fa fa-3x fa-bullseye"
                    style={{ color: "white" }}
                  ></i>
                </div>
                <div className="ms-4">
                  <h5 className="mb-3">Our Mission</h5>
                  <span>
                    Transforming Customer Perspectives by Providing More Than
                    Just Services – Providing Peace of Mind through the Reliable
                    and Secure Hands of {companyname}.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End */}
      {/* Service Start */}
      {/* Service End */}
      {/* Quote Start */}
      <div className="container-fluid overflow-hidden my-5 px-lg-0">
        <div className="container quote px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div
              className="col-lg-6 quote-text"
              data-parallax="scroll"
              data-image-src="img/carousel-1.jpg"
            >
              <div
                className="h-100 px-4 px-sm-5 ps-lg-0 wow fadeIn"
                data-wow-delay="0.1s"
              >
                <h1 className="text-white mb-4">
                Seamless Shipping, Endless Possibilities
                </h1>
                <p className="text-light mb-5">
                  Guided by an unwavering commitment to your success, we
                  amalgamate our insightful market acumen with a strong penchant
                  for optimization. This ensures that your supply chain not only
                  thrives but flourishes, while your commercial endeavors
                  experience significant prosperity. Our dedication to
                  delivering tangible results transcends mere promises; it
                  reflects our steadfast commitment to serving as your trusted
                  partner. This partnership empowers your business to not only
                  endure but to genuinely prosper within the dynamic and
                  fiercely competitive global marketplace.
                </p>
                <Link to="/About" className="align-self-start btn btn-primary py-3 px-5">
                  More Details
                </Link>
              </div>
            </div>
            <div
              className="col-lg-6 quote-form"
              data-parallax="scroll"
              data-image-src="img/carousel-2.jpg"
            >
              <div
                className="h-100 px-4 px-sm-5 pe-lg-0 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <div className="bg-white p-4 p-sm-5">
                  <div className="row g-3">
                    <form
                      action="/php/getquoteHome.php"
                      method="post"
                      onSubmit={(event) => handleSubmit(event)}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="comment-form__input-box">
                            <input
                              className="form-control"
                              value={uname}
                              onChange={(event) => handleUnameChange(event)}
                              style={{
                                width: "100%",
                                padding: "10px",
                                margin: "6px 0",
                              }}
                              type="text"
                              name="uname"
                              id="uname"
                              placeholder="Enter your Fullname"
                              required="Please Enter the Fullname"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="comment-form__input-box">
                            <input
                              className="form-control"
                              value={uemail}
                              onChange={(event) => handleUemailChange(event)}
                              style={{
                                width: "100%",
                                padding: "10px",
                                margin: "6px 0",
                              }}
                              type="email"
                              name="uemail"
                              id="uemail"
                              placeholder="Enter Your Email"
                              required="Please Enter Your Email"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="comment-form__input-box">
                            <input
                              className="form-control"
                              value={uphone}
                              onChange={(event) => handleUphoneChange(event)}
                              style={{
                                width: "100%",
                                padding: "10px",
                                margin: "6px 0",
                              }}
                              type="text"
                              name="uphone"
                              id="uphone"
                              placeholder="Enter Phone Number"
                              required="Please Enter Phone Number"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="comment-form__input-box">
                            <input
                              className="form-control"
                              value={subject}
                              onChange={(event) => handlesubjectChange(event)}
                              type="text"
                              style={{
                                width: "100%",
                                padding: "10px",
                                margin: "6px 0",
                              }}
                              id="subject"
                              name="subject"
                              placeholder="Enter subject"
                            />
                          </div>
                        </div>
                        <div className="col-12" style={{ padding: "10px" }}>
                          <select
                            className="form-control"
                            name="uservice"
                            id="uservice"
                            value={uservice}
                            onChange={(event) => handleuserviceChange(event)}
                            style={{
                              outline: "none",
                              color: "#333",
                              fontSize: "14px",
                              textTransform: "capitalize",
                              letterSpacing: "2px",
                              width: "100%",
                              border: "1px solid #ddd",
                              textAlign: "left",
                              background: "#F9FAFF",
                            }}
                          >
                            <option selected>Service Required</option>
                            <option value="Air Freight Forwarding">
                              Air Freight Forwarding
                            </option>
                            <option value="Sea Freight Forwarding">
                              Sea Freight Forwarding
                            </option>
                            <option value="Road Freight Forwarding">
                              Road Freight Forwarding
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          name="submitquote"
                          defaultValue="SEND"
                          className="btn btn-primary w-100 py-3"
                        >
                          Send Request
                        </button>
                      </div>
                      <br />
                    </form>
                    <h5 style={{ color: "red" }}>{result}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Quote End */}
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <h1 className="display-6 mb-5">Our Trading Solutions</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="team-img position-relative overflow-hidden">
                  <img className="img-fluid" src="img/Apparel.jpg" alt="" />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary py-3 px-5 animated slideInDown"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h5>Apparel</h5>
                  <span className="text-primary">Trading Solutions</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Fruits & Vegetables.jpg"
                    alt=""
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary py-3 px-5 animated slideInDown"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h5>Fruits & Vegetables</h5>
                  <span className="text-primary">Trading Solutions</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Food & Beverages.jpg"
                    alt=""
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary py-3 px-5 animated slideInDown"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h5>Food & Beverages</h5>
                  <span className="text-primary">Trading Solutions</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Chemicals & Petrochemicals.jpg"
                    alt=""
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary py-3 px-5 animated slideInDown"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h5>Chemicals & Petrochemicals</h5>
                  <span className="text-primary">Trading Solutions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}
      <Footer />
    </>
  );
}
