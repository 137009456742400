import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Road Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Road Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">Road Freight</h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Road Freight.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    Our extensive road networks serve as a testament to
                    well-established and intricately woven pathways that
                    crisscross regions throughout India. These road systems have
                    been thoughtfully developed to seamlessly integrate within
                    diverse geographical landscapes. The outcome is a network
                    that transcends borders, ensuring efficient connectivity and
                    the smooth flow of goods.
                  </p>

                  <p>
                    At the core of our operations lies an unwavering commitment
                    to reliability. We meticulously plan and execute our
                    domestic road flows, placing a strong emphasis on safety,
                    sustainability, and cost-effectiveness. We understand that
                    the transportation of goods along these routes is not just a
                    matter of logistics; it's a vital link in the supply chain
                    that demands utmost precision. From cutting-edge tracking
                    systems to stringent safety protocols, we spare no effort in
                    guaranteeing the security and timely delivery of your
                    shipments. This dedication translates into a transportation
                    partner you can trust, time and time again.
                  </p>

                  <p>
                    Our capabilities extend beyond the physical roads
                    themselves. We are fully equipped to handle a diverse array
                    of cargo types, demonstrating our versatility and
                    adaptability. Whether it involves the delicate
                    transportation of pharmaceuticals requiring
                    temperature-controlled environments, the responsible
                    carriage of chemicals, the efficient distribution of
                    consumer goods to meet market demands, or the safe and
                    punctual delivery of vehicles and heavy machinery, our
                    expertise spans the entire spectrum. With a comprehensive
                    understanding of the unique requirements of each cargo
                    category, we tailor our services to ensure that your goods
                    arrive at their destination in optimal condition, on
                    schedule, and with the utmost care.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
