import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Trading Solutions
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Trading Solutions
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5">Apparel</h1>

                <p className="mb-4">
                  The fashion and clothing sector is undergoing swift changes,
                  creating heightened demands on manufacturing facilities,
                  suppliers, distributors, retail establishments, and storage
                  facilities. Leveraging our widespread international reach and
                  extensive background in furnishing logistical and shipping
                  solutions to this field, {companyname} offers a
                  comprehensive solution to cater to your entire spectrum of
                  supply chain transportation requirements.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-3">
                <img
                  className="img-fluid w-75 wow zoomIn"
                  data-wow-delay="0.7s"
                  src="img/Apparel.jpg"
                />
              </div>
            </div>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="row g-3">
                <img
                  className="img-fluid w-75 wow zoomIn"
                  data-wow-delay="0.7s"
                  src="img/Fruits & Vegetables.jpg"
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5">Fruits & Vegetables</h1>

                <p className="mb-4">
                  Modern customers anticipate a consistent provision of fresh,
                  delectable, and aesthetically pleasing fruits and vegetables.
                  Ensuring the impeccable condition of these fragile, perishable
                  items during their journey from farm to ultimate delivery
                  point, and simultaneously maintaining their flavor, crispness,
                  texture, and fragrance, necessitates swiftness, adaptability,
                  and cutting-edge temperature control technology.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5">Food & Beverages</h1>

                <p className="mb-4">
                  In order to meet the global demand for delicious beverages,
                  producers and distributors must ensure the swift and effective
                  movement of these products. {companyname} provides
                  rapid and dependable transportation options to various
                  destinations. Therefore, regardless of the type of beverages
                  being conveyed, our specialists are here to offer the smooth
                  assistance essential for your fluid supply chain requirements.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-3">
                <img
                  className="img-fluid w-75 wow zoomIn"
                  data-wow-delay="0.7s"
                  src="img/Food & Beverages.jpg"
                />
              </div>
            </div>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="row g-3">
                <img
                  className="img-fluid w-75 wow zoomIn"
                  data-wow-delay="0.7s"
                  src="img/Chemicals & Petrochemicals.jpg"
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5">Chemicals & Petrochemicals</h1>

                <p className="mb-4">
                  {companyname} employs meticulous, exacting, and
                  resilient procedures to securely convey hazardous and
                  potentially harmful materials like chemicals and
                  petrochemicals. As proficient authorities in transporting
                  perilous freight, {companyname} guarantees the
                  secure shipment of your cargo while adhering to pertinent
                  guidelines. Furthermore, we stand ready to assist you in
                  managing unforeseen circumstances regardless of your shipping
                  destination across the world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
