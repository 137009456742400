import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Contact Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Contact
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* Contact Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <h1 className="display-6 mb-5">
                If You Have Any Query, Please Contact Us
              </h1>
              <p className="mb-4">
                Time to optimize your supply chain – start by submitting the
                form for a tailored solution.
              </p>
              <form
                id="contactForm"
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        required
                      />
                      <label htmlFor="name">Enter your first name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                        required
                      />
                      <label htmlFor="email">Enter your last name</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                       className="form-control"
                       onChange={(event) => handleemailChange(event)}
                       type="email"
                       id="email"
                       name="email"
                       value={email}
                       placeholder="Enter your Email"
                       required
                      />
                      <label htmlFor="subject">Enter your Email</label>
                    </div>
                  </div> <div className="col-12">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                        required
                      />
                      <label htmlFor="subject">Subject</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                     
                      />
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary py-3 px-5"type="submit"
                        defaultValue="">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-delay="0.5s"
              style={{ minHeight: "450px" }}
            >
              <div className="position-relative overflow-hidden h-100">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.23164373565!2d72.95158527433667!3d19.185082248540713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b9fbaf6defb3%3A0x8dcbb2b8b3a7dd4b!2sRatanbai%20Compound!5e0!3m2!1sen!2sin!4v1694425923521!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}
      <Footer />
    </>
  );
}
