import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Air Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Air Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">Air Freight</h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Air Freight.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    We pride ourselves on our agility and adaptability when it
                    comes to meeting your air freight requirements. Whether you
                    need urgent Next Flight Out services or seamless same-day
                    options, our solutions cover both domestic and international
                    territories. We go beyond standard services to offer
                    specialized solutions like On-Board Couriers, ensuring we
                    meet your unique needs. With our expertise, extensive
                    experience, and global reach, we are poised to enhance your
                    business operations.
                  </p>

                  <p>
                    By strategically collaborating with renowned international
                    carriers known for their vast capacity and global influence,
                    we develop personalized logistics strategies that align
                    precisely with your requirements while keeping costs
                    budget-friendly.
                  </p>

                  <p>
                    If you're wondering if freight forwarding is the right
                    choice for your business, let us clarify. Our proactive
                    approach involves reserving and pre-purchasing airline
                    capacity well in advance, enabling us to readily accommodate
                    your time-sensitive same-day freight shipping needs. We have
                    the expertise to handle a wide range of goods
                    transportation, and our reach extends to thousands of
                    destinations across hundreds of countries worldwide. In
                    addition to seamless collection and delivery, we efficiently
                    navigate the complexities of essential customs and carnet
                    paperwork.
                  </p>

                  <p>
                    Our commitment extends to both international and domestic
                    domains, establishing us as a reliable leader in air freight
                    forwarding. We maintain strong partnerships with top-tier
                    international carriers, demonstrating our dedication to
                    providing you with comprehensive solutions tailored to your
                    unique requirements.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
