import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Sea Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Sea Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">Sea Freight</h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Sea Freight.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    Collaborating with renowned ocean carriers, we present a
                    comprehensive array of options that grant you full
                    visibility and flexibility. From Specialized Equipment
                    Shipping designed to accommodate unique cargo, to Full
                    Container Load (FCL), Less than Container Load (LCL), and
                    our innovative Hybrid LCL offerings, we extend our services
                    to a multitude of destinations and alliances. Through our
                    vessels equipped with GPS tracking and augmented by
                    third-party data integration, you're assured real-time
                    updates on the status of your ocean freight.
                  </p>

                  <p>
                    Acknowledging the pivotal role of ocean freight, we approach
                    each shipment with meticulous attention, aiming to optimize
                    both space and equipment utilization. By partnering with
                    carrier experts who have honed their expertise, we strive to
                    enhance the efficiency of your shipments. Rest assured, your
                    cargo is entrusted to capable hands, as we recognize the
                    unforgiving nature of this industry where precision is
                    paramount. Businesses rely on the steadfast commitment of
                    timely and effective shipping procedures, and we
                    wholeheartedly understand the zero-tolerance stance for
                    errors in this field.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
